import { Link } from "gatsby";
import React from "react";
import "./Navigation.less";
import PageWidth from "../../organisms/PageWidth/PageWidth";

const Navigation = () => {
  const isActive = (props) => {
    return props.isCurrent ? { className: "active" } : null;
  };

  return (
    <PageWidth>
      <nav className="nav">
        <ol className="nav__list">
          <li className="nav__item">
            <Link to="/" getProps={isActive}>
              Home
            </Link>
          </li>
          <li className="nav__item">
            <Link to="/freelance" getProps={isActive}>
              Freelance
            </Link>
          </li>
        </ol>
      </nav>
    </PageWidth>
  );
};

export default Navigation;
