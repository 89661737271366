import React from 'react'
import './PageWidth.less'

const PageWidth = ({children, isContent}) => {

    let className = ["page-width"];
    if (isContent) {
        className.push("page-width--homepage")
    }

    return <div className={className.join(" ")}>
        {children}
    </div>
}

PageWidth.defaultProps = {
    isContent: false
}

export default PageWidth
