import { Link } from 'gatsby'
import React from 'react'
import './Footer.less'
import PageWidth from "../../organisms/PageWidth/PageWidth";

const Footer = () => {
    return <PageWidth>
        <div className="footer">
            Karl Ludwig Weise, Hamburg, Germany.
            <ol className="footer__list">
                <li className="footer__item">
                    <Link to="/imprint">Imprint</Link>.
                </li>
                <li className="footer__item">
                    <Link to="/privacy-policy">Privacy Policy</Link>.
                </li>
            </ol>
        </div>
    </PageWidth>
}

Footer.propTypes = {

}

Footer.defaultProps = {

}

export default Footer
